<template>
  <div></div>
</template>

<script>
export default {
  beforeCreate() {
    window.location = "http://app.hint.video/" + window.location.pathname
  }
}
</script>